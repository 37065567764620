import { MainFilterInterface } from 'modules/main/types/main-filter-interface'
import {
  MainEventsApiInterface,
  MainLeadsInterface,
  MainRevenueInterface,
} from 'modules/main/types/main-interface'
import { useApiWrapperWithErrorValidation } from 'shared/hooks/use-api-wrapper-with-error-validation'
import { RequestMethodsEnum } from 'shared/hooks/use-api-wrapper-with-error-validation/types'
import useUser from 'shared/hooks/use-user'
import useSWR from 'swr'

export const MAIN_API = '/api/dashboard/customer/main-dashboard'

export function useMain(filter: MainFilterInterface) {
  const { user } = useUser()
  const { fetcher: leadsFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    MainLeadsInterface
  >({
    method: RequestMethodsEnum.get,
  })

  const { fetcher: eventsFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    MainEventsApiInterface
  >({
    method: RequestMethodsEnum.get,
  })

  const { fetcher: revenueFetcher } = useApiWrapperWithErrorValidation<
    RequestMethodsEnum.get,
    MainRevenueInterface
  >({
    method: RequestMethodsEnum.get,
  })

  const filterApi = `${filter.dateSince !== null ? `&filter[dateSince]=${filter.dateSince}` : ''}${
    filter.dateTill !== null ? `&filter[dateTill]=${filter.dateTill}` : ''
  }`

  const { data: leads } = useSWR<MainLeadsInterface>(() => {
    if (user && filter.dateTill !== null && filter.dateSince !== null) {
      return [`${MAIN_API}/leads?${filterApi}`, user]
    }
    return null
  }, ([url]) => leadsFetcher(url))

  const { data: events } = useSWR(() => {
    if (user) {
      return [`${MAIN_API}/events?pagination[order]=next&pagination[limit]=15`, user]
    }
    return null
  }, ([url]) => eventsFetcher(url))

  const { data: revenue } = useSWR(() => {
    if (user && filter.dateTill !== null && filter.dateSince !== null) {
      return [`${MAIN_API}/revenue?${filterApi}`, user]
    }
    return null
  }, ([url]) => revenueFetcher(url))

  return { leads, events, revenue }
}
