import React from 'react'

function MoneySucceedIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.16"
      height="18.511"
      viewBox="0 0 28.16 18.511"
      {...props}
    >
      <g transform="translate(-1365.792 -6.141)">
        <g transform="translate(1366.044 6.458)">
          <path
            d="M1331.552,30h-7.443a6.109,6.109,0,0,0,7.443,7.085Z"
            transform="translate(-1303.95 -30)"
            fill="#35e190"
          />
          <path
            d="M1323.9,29.75h7.9v7.532l-.191.046a6.359,6.359,0,0,1-7.748-7.375Zm7.4.5h-6.983a5.859,5.859,0,0,0,6.983,6.635Z"
            transform="translate(-1303.95 -30)"
            fill="#35e190"
          />
          <path
            d="M877,255.148h7.443A6.109,6.109,0,0,0,877,248.063Z"
            transform="translate(-877 -237.205)"
            fill="#35e190"
          />
          <path
            d="M884.65,255.4h-7.9v-7.532l.191-.046a6.359,6.359,0,0,1,7.748,7.375Zm-7.4-.5h6.983a5.859,5.859,0,0,0-6.983-6.635Z"
            transform="translate(-877 -237.205)"
            fill="#35e190"
          />
          <circle cx="3.85" cy="3.85" r="3.85" transform="translate(9.968 5.122)" fill="#35e190" />
          <path
            d="M3.85-.25a4.1,4.1,0,1,1-4.1,4.1A4.1,4.1,0,0,1,3.85-.25Zm0,7.7a3.6,3.6,0,1,0-3.6-3.6A3.6,3.6,0,0,0,3.85,7.449Z"
            transform="translate(9.968 5.122)"
            fill="#35e190"
          />
          <path
            d="M878.562,46.276V31.667h24.479c.2,2.75-.71,9.324,1.562-1.667H877l.076,17.575Z"
            transform="translate(-877 -30)"
            fill="#35e190"
          />
          <path
            d="M876.828,48.123l-.079-18.373h28.16l-.062.3c-.467,2.258-.811,3.832-1.053,4.812-.118.477-.213.82-.29,1.047s-.163.479-.4.479a.274.274,0,0,1-.2-.084c-.149-.153-.154-.467-.113-2,.021-.794.045-1.684.012-2.386H878.812V46.389Zm.423-17.873.072,16.776.988-.864V31.417h24.962l.017.232c.054.754.027,1.77,0,2.667,0,.175-.01.361-.014.543.236-.946.575-2.486,1.016-4.609Z"
            transform="translate(-877 -30)"
            fill="#35e190"
          />
          <path
            d="M878.5,45.981V31.637h23.492A20.627,20.627,0,0,0,903.49,30H877V47.618Z"
            transform="translate(904.602 47.943) rotate(180)"
            fill="#35e190"
          />
          <path
            d="M876.75,48.261V29.75H903.8l-.06.3c-.016.078-.045.224-1.566,1.763l-.073.074H878.749V46.078Zm.5-18.011V46.974l1-1.091v-14.5h23.638c.4-.405.814-.84,1.083-1.137Z"
            transform="translate(904.602 47.943) rotate(180)"
            fill="#35e190"
          />
        </g>
      </g>
    </svg>
  )
}

export default MoneySucceedIcon
