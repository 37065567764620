import React from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import ArrowDownSmoothIcon from 'shared/icons/arrow-down-smooth-icon'
import { isYesterday } from 'shared/utils/date-time-format'

export interface ChartBoxProps {
  title: string
  total: string
  diffPercents: number
  countDays: number
  dateSince: string | null
  chart: JSX.Element
}

const ChartBox = ({ title, total, diffPercents, countDays, dateSince, chart }: ChartBoxProps) => {
  const { t } = useLocoTranslation()
  const { user } = useUser()
  return (
    <div
      className={
        'rounded-lg shadow-old-design flex justify-between font-avertape flex-col bg-white w-full h-[340px] md:w-[calc(50%-10px)] lg:w-full old-desktop:w-[calc(50%-10px)] pt-6 pb-1'
      }
    >
      <div className={'flex justify-between px-6'}>
        <div className={'flex flex-col gap-2.5'}>
          <span className={'text-gray-300 leading-none font-semibold'}>{title}</span>
          <span className={'text-blue text-4xl leading-none'}>{total}</span>
        </div>
        <div
          className={`${
            diffPercents >= 0 ? 'bg-lightgreen text-old-green' : 'bg-lightred text-old-red'
          } pt-3 px-3.5 pb-2 rounded-md flex flex-col h-fit`}
        >
          <div className={'flex gap-2 items-center'}>
            <ArrowDownSmoothIcon
              className={`${
                diffPercents >= 0 ? 'fill-old-green' : 'fill-old-red rotate-180'
              } scale-90`}
            />
            <span className={`text-[20px] font-bold leading-5`}>{diffPercents}%</span>
          </div>
          <span className={'text-xs'}>
            {isYesterday(dateSince, user?.timezone) && countDays === 1
              ? t('dashboard.main.one_day_ago').toLowerCase()
              : t('dashboard.main.last_days', { count: countDays }).toLowerCase()}
          </span>
        </div>
      </div>
      <div className={'ml-[0px] mr-[11px]'}>{chart}</div>
    </div>
  )
}
export default ChartBox
