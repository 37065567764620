import React from 'react'
import useWindowSize from 'shared/hooks/use-window-size'

interface EventsSkeletonInterface {
  revenueLength: number
}

const EventsSkeleton = ({ revenueLength }: EventsSkeletonInterface) => {
  const { width } = useWindowSize()
  return (
    <>
      <div className={`px-[25px] flex flex-col`}>
        {Array.from(Array(8).keys()).map((el, idx) => (
          <div key={idx}>
            <hr className={'color-darkblue opacity-10'} />
            <div className={'py-4 flex flex gap-2'}>
              <div
                className={
                  'rounded-full bg-gray-600 animate-pulse h-[24px] min-w-[24px] flex text-transparent'
                }
              >
                .
              </div>
              <div className={'flex flex-col w-full gap-1'}>
                <div className={'flex justify-between items-center'}>
                  <div className={'text-transparent bg-gray-600 animate-pulse rounded-md w-[80px]'}>
                    .
                  </div>
                  <div
                    className={
                      'text-transparent h-[20.4px] bg-gray-600 animate-pulse rounded-md w-[100px]'
                    }
                  >
                    .
                  </div>
                </div>
                <div className={'text-transparent h-[21.7px] bg-gray-600 animate-pulse rounded-md'}>
                  .
                </div>
              </div>
            </div>
          </div>
        ))}
        {revenueLength > 3 &&
          Array.from(Array(width > 1023 && width < 1150 ? 13 : 4).keys()).map((el, idx) => (
            <div key={idx}>
              <hr className={'color-darkblue opacity-10'} />
              <div className={'py-4 flex flex gap-2'}>
                <div
                  className={
                    'rounded-full bg-gray-600 animate-pulse h-[24px] min-w-[24px] flex text-transparent'
                  }
                >
                  .
                </div>
                <div className={'flex flex-col w-full gap-1'}>
                  <div className={'flex justify-between items-center'}>
                    <div
                      className={'text-transparent bg-gray-600 animate-pulse rounded-md w-[80px]'}
                    >
                      .
                    </div>
                    <div
                      className={
                        'text-transparent h-[20.4px] bg-gray-600 animate-pulse rounded-md w-[100px]'
                      }
                    >
                      .
                    </div>
                  </div>
                  <div
                    className={'text-transparent h-[21.7px] bg-gray-600 animate-pulse rounded-md'}
                  >
                    .
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  )
}
export default EventsSkeleton
