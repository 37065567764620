export enum ContactSlugEnum {
  email = 'email',
  first_name = 'first_name',
  surname = 'surname',
  country = 'country',
  city = 'city',
  street_address = 'street_address',
  postcode = 'postcode',
  phone_number = 'phone_number',
  company_name = 'company_name',
  tax_number = 'tax_number',
  state = 'state',
}

export enum ContactSearchFieldEnum {
  last_activity_date = 'search_field_last_activity_date',
  created_at = 'search_field_created_at',
  tag = 'search_field_tag',
  state = 'search_field_state',
  affiliate = 'search_field_affiliate',
  removed_at = 'search_field_removed_at',
  removed_reason_type = 'removed_reason_type',
}

export enum ContactFilterConditionEnum {
  equals = 'EQUALS',
  not_equal = 'NOT_EQUAL',
  contains = 'CONTAINS',
  does_not_contain = 'DOES_NOT_CONTAIN',
  begins = 'BEGINS',
  ends = 'ENDS',
  does_not_end = 'DOES_NOT_END',
  does_not_begin = 'DOES_NOT_BEGIN',
  not_set = 'NOT_SET',
}

export enum ContactFilterDateConditionEnum {
  greater_than = 'GREATER_THAN',
  less_than = 'LESS_THAN',
  equals_date = 'EQUALS_DATE',
}

export enum ContactFilterTagConditionEnum {
  in_list = 'IN_LIST',
  not_in_list = 'NOT_IN_LIST',
  without = 'WITHOUT',
}

export enum ContactFilterContactStateEnum {
  state_active = 'state_active',
  state_unsubscribed = 'state_unsubscribed',
  state_bounced = 'state_bounced',
  state_made_payments = 'state_made_payments',
  state_not_made_payments = 'state_not_made_payments',
}

export enum ContactFilterRemovedReasonTypeEnum {
  manual = 'manual',
  sendgrid_invalid_email = 'sendgrid_invalid_email',
  invalid_email = 'invalid_email',
  automatically_by_condition = 'automatically_by_condition',
  automatically_from_sio_students = 'automatically_from_sio_students',
}
