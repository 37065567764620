import React, { PropsWithChildren } from 'react'
import { BaseButtonType } from 'shared/components/button/types/button-interface'
import LinkWithoutPrefetch, { LinkType } from 'shared/components/link-without-prefetch'
import { twMerge } from 'tailwind-merge'

type ButtonLinkWrapperProps = LinkType & Pick<BaseButtonType, 'isFetching' | 'buttonTextClassName'>

const ButtonLinkWrapper = ({
  href,
  isFetching,
  className,
  children,
  buttonTextClassName,
  ...baseLinkProps
}: ButtonLinkWrapperProps) => {
  if (baseLinkProps['aria-disabled'] || isFetching) {
    return (
      <button className={className} disabled={!!baseLinkProps['aria-disabled']}>
        <ButtonInnerWrapper buttonTextClassName={buttonTextClassName} isFetching={isFetching}>
          {children}
        </ButtonInnerWrapper>
      </button>
    )
  }
  return (
    <LinkWithoutPrefetch className={className} href={href as string} {...baseLinkProps}>
      <ButtonInnerWrapper buttonTextClassName={buttonTextClassName} isFetching={isFetching}>
        {children}
      </ButtonInnerWrapper>
    </LinkWithoutPrefetch>
  )
}
export default ButtonLinkWrapper

export const ButtonInnerWrapper = ({
  children,
  isFetching,
  buttonTextClassName,
}: PropsWithChildren<Pick<BaseButtonType, 'isFetching' | 'buttonTextClassName'>>) => (
  <div
    className={twMerge(
      'flex items-center justify-center gap-2 truncate',
      isFetching && 'opacity-0',
      buttonTextClassName,
    )}
  >
    {children}
  </div>
)
