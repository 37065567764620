import { contactFilterTitles } from 'modules/contacts/constants/contact-filter-titles'
import { ContactSlugEnum } from 'modules/contacts/enums/contact-enum'
import { ContactId } from 'modules/contacts/types/contact-interface'
import { CustomerTypeEnum } from 'modules/profile/user-settings/enums/customer-type-enum'
import React from 'react'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import { Tooltip } from 'shared/components/tooltip'
import { CountryEnum } from 'shared/enums/country-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { usePhoneInput } from 'shared/react-international-phone'
import { getSingleCountry } from 'shared/utils/get-country-data'

type FieldType = { contactField: { fieldName: string | null; slug: string }; value: string }

interface ContactInfoTooltipProps<T extends FieldType> {
  children?: JSX.Element | string
  fields: T[]
  referredByEmail?: string
  type?: keyof typeof CustomerTypeEnum
  contactId?: ContactId
  oldDesignedLink?: boolean
  wrapperClassName?: string
}

const ContactInfoTooltip = <T extends FieldType>({
  children,
  fields,
  referredByEmail,
  type,
  contactId,
  oldDesignedLink,
  wrapperClassName,
}: ContactInfoTooltipProps<T>) => {
  const { t } = useLocoTranslation()
  const { user } = useUser()
  const phoneNumber = fields.find(
    el => el.contactField.slug === ContactSlugEnum.phone_number,
  )?.value
  const { phone: formattedPhoneNumber } = usePhoneInput({ value: phoneNumber })
  const getSlugValue = (slug: ContactSlugEnum, value: string) => {
    switch (slug) {
      case ContactSlugEnum.country:
        return Object.values(CountryEnum).includes(value as CountryEnum)
          ? getSingleCountry(value as CountryEnum, user?.dashboardLocale)
          : value
      case ContactSlugEnum.phone_number: {
        return formattedPhoneNumber
      }
      default: {
        return value
      }
    }
  }

  return (
    <Tooltip
      label={
        <div className="flex flex-col items-start gap-2 text-start">
          {fields.map(el => {
            if (el.contactField.slug in ContactSlugEnum) {
              return (
                <p key={el.contactField.slug}>
                  <span className="font-bold text-darkblue">
                    {t(contactFilterTitles[el.contactField.slug as ContactSlugEnum])}:{' '}
                  </span>
                  <span>{getSlugValue(el.contactField.slug as ContactSlugEnum, el.value)}</span>
                </p>
              )
            } else {
              return (
                <p key={el.contactField.fieldName}>
                  <span className="font-bold text-darkblue">{el.contactField.fieldName}: </span>
                  <span>{el.value}</span>
                </p>
              )
            }
          })}
          {type && (
            <p>
              <span className="font-bold text-darkblue">{t('global.type')}: </span>
              <span>{type}</span>
            </p>
          )}
          <p>
            <span className="font-bold text-darkblue">{t('dashboard.user.affiliate')}: </span>
            <span>{referredByEmail ? referredByEmail : t('global.none')}</span>
          </p>
        </div>
      }
      mode={'hover'}
      className="truncate w-full max-w-fit"
      wrapperClassName={`${wrapperClassName || 'w-full'}`}
    >
      {!contactId ? (
        children
      ) : (
        <LinkWithoutPrefetch
          className={
            oldDesignedLink
              ? 'underline hover:text-gray-300/70 truncate'
              : 'primary-link w-full truncate'
          }
          href={`/contacts/${contactId}`}
        >
          {children}
        </LinkWithoutPrefetch>
      )}
    </Tooltip>
  )
}

export default ContactInfoTooltip
