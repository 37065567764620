import ContactInfoTooltip from 'modules/contacts/components/contact-info-tooltip'
import { getFunnelStepUrl } from 'modules/funnels/funnel/utils/get-funnel-step-url'
import {
  MainEventsEnum,
  mainEventsDescription,
  mainEventsType,
} from 'modules/main/enums/main-events-enum'
import { MainEventType } from 'modules/main/types/main-interface'
import React from 'react'
import LinkWithoutPrefetch from 'shared/components/link-without-prefetch'
import TransLoco from 'shared/components/trans-loco'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import MoneyCancelIcon from 'shared/icons/money-cancel-icon'
import MoneyReturnIcon from 'shared/icons/money-return-icon'
import MoneySucceedIcon from 'shared/icons/money-succeed-icon'
import PersonIcon from 'shared/icons/person-icon'
import { currencyFormat } from 'shared/utils/currency-format'
import { dateTimeFormat } from 'shared/utils/date-time-format'
import { getBlogPostNextjsUrl } from 'shared/utils/get-blog-post-nextjs-url'

interface RenderEventProps {
  event: MainEventType
  lastItem?: boolean
}

const eventIcon: Record<MainEventsEnum, JSX.Element> = {
  [MainEventsEnum.contact_created]: <PersonIcon className={'h-[24px] w-[24px]'} />,
  [MainEventsEnum.recurring_charge_succeed]: <MoneyReturnIcon />,
  [MainEventsEnum.one_shot_charge_succeed]: <MoneySucceedIcon />,
  [MainEventsEnum.payment_subscription_stopped]: <MoneyCancelIcon />,
  [MainEventsEnum.payment_subscription_completed]: <MoneyReturnIcon />,
  [MainEventsEnum.payment_subscription_created]: <MoneyReturnIcon />,
  [MainEventsEnum.recurring_charge_failed]: <MoneyCancelIcon />,
}

function RenderEvent({ event, lastItem }: RenderEventProps) {
  const { t } = useLocoTranslation()
  const { user } = useUser()
  const getEventElement = (): JSX.Element => {
    switch (event.type) {
      case MainEventsEnum.recurring_charge_succeed:
      case MainEventsEnum.one_shot_charge_succeed:
        return (
          <TransLoco
            t={t}
            i18nKey={mainEventsDescription[MainEventsEnum.recurring_charge_succeed]}
            values={{
              price: currencyFormat(
                event.relatedObject.amount / 100,
                event.relatedObject.payment.currency,
                user?.dashboardLocale,
              ),
              offer_name: event.relatedObject.orderItem.offerPricePlan
                ? event.relatedObject.orderItem.offerPricePlan.innerName
                : event.relatedObject.orderItem.productOrderItem
                ? event.relatedObject.orderItem.productOrderItem.product.name
                : '',
            }}
            components={{
              a:
                event.relatedObject.orderItem.offer &&
                event.relatedObject.orderItem.offer.funnelStep ? (
                  <LinkWithoutPrefetch
                    className="underline truncate hover:text-gray-300/70"
                    href={getFunnelStepUrl(
                      event.relatedObject.orderItem.offer.funnelStep.funnel.id,
                      event.relatedObject.orderItem.offer.funnelStep.id,
                    )}
                  />
                ) : (
                  <span />
                ),
            }}
          />
        )
      case MainEventsEnum.recurring_charge_failed:
      case MainEventsEnum.payment_subscription_created:
      case MainEventsEnum.payment_subscription_stopped:
      case MainEventsEnum.payment_subscription_completed:
        return (
          <TransLoco
            t={t}
            i18nKey={mainEventsDescription[event.type]}
            values={{
              customer_name:
                event.relatedObject.orderItem.order.customer.fullName ||
                event.relatedObject.orderItem.order.customer.contact.connectedUser?.fullName ||
                event.relatedObject.orderItem.order.customer.contact.email,
              offer_name: event.relatedObject.orderItem.offerPricePlan
                ? event.relatedObject.orderItem.offerPricePlan.innerName
                : event.relatedObject.orderItem.productOrderItem
                ? event.relatedObject.orderItem.productOrderItem.product.name
                : '',
            }}
            components={{
              a: (
                <ContactInfoTooltip
                  referredByEmail={event.relatedObject.orderItem.order.customer.referredBy?.email}
                  fields={event.relatedObject.orderItem.order.customer.contact.fieldValues}
                  contactId={event.relatedObject.orderItem.order.customer.contact.id}
                  oldDesignedLink
                />
              ),
              b:
                event.relatedObject.orderItem.offer &&
                event.relatedObject.orderItem.offer.funnelStep ? (
                  <LinkWithoutPrefetch
                    className="underline truncate hover:text-gray-300/70"
                    href={getFunnelStepUrl(
                      event.relatedObject.orderItem.offer.funnelStep.funnel.id,
                      event.relatedObject.orderItem.offer.funnelStep.id,
                    )}
                  />
                ) : (
                  <span />
                ),
            }}
          />
        )
      case MainEventsEnum.contact_created: {
        const funnelStep = event.relatedObject.sourcePage?.funnelStep && (
          <LinkWithoutPrefetch
            className="underline hover:text-gray-300/70"
            href={getFunnelStepUrl(
              event.relatedObject.sourcePage.funnelStep.funnel.id,
              event.relatedObject.sourcePage.funnelStep.id,
            )}
          />
        )
        const blogPage = event.relatedObject.sourcePage?.blogPage && (
          <LinkWithoutPrefetch
            className="underline hover:text-gray-300/70"
            href={getBlogPostNextjsUrl(event.relatedObject.sourcePage.blogPage.blog.id)}
          />
        )
        return (
          <TransLoco
            t={t}
            i18nKey={mainEventsDescription[MainEventsEnum.contact_created]}
            values={{
              email: event.relatedObject.email,
              form: event.relatedObject.pageTitle,
            }}
            components={{
              a: (
                <ContactInfoTooltip
                  referredByEmail={event.relatedObject.referredBy?.email}
                  fields={event.relatedObject.fieldValues}
                  contactId={event.relatedObject.id}
                  oldDesignedLink
                />
              ),
              b: event.relatedObject.sourcePage ? funnelStep || blogPage || <span /> : <span />,
            }}
          />
        )
      }
    }
  }
  return (
    <>
      <div className={`${lastItem ? 'pt-4' : 'py-[15px]'} flex gap-2.5 font-avertape`}>
        <div
          className={
            'rounded-full h-[24px] min-w-[28px] flex justify-center items-center [&>svg]:scale-75'
          }
        >
          {eventIcon[event.type]}
        </div>
        <div className={'flex truncate flex-col w-full gap-1'}>
          <div className={'flex gap-3 items-center justify-between'}>
            <span className={'text-[#414355] font-bold'}>{t(mainEventsType[event.type])}</span>
            <span className={'text-xs truncate text-[#9ba9b3]'}>
              {dateTimeFormat(event.createdAt, {
                timeFormat: user?.timeFormat,
                dateFormat: user?.dateFormat,
                timeZone: user?.timezone,
              })}
            </span>
          </div>
          <div className={'text-xs truncate leading-normal flex gap-1 flex-wrap'}>
            {getEventElement()}
          </div>
        </div>
      </div>
      {!lastItem && <hr className={'color-darkblue opacity-10'} />}
    </>
  )
}

export default RenderEvent
