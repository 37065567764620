export type DataTestAttributesType = Record<string, string>

export const getDataTestAttributes = (
  isVisible: boolean,
  dataTestPostfix?: string,
): DataTestAttributesType => {
  return {
    ...(isVisible
      ? {
          ['data-test-id']: `${dataTestPostfix}`,
        }
      : {}),
  }
}
