import { HTMLString } from 'shared/types/html-string'

export type FieldErrorAndDescriptionProps = {
  description?: React.ReactNode
  descriptionClassName?: string
  error?: HTMLString | string | null
  errorClassName?: string
}

export const FieldErrorAndDescription = ({
  description,
  descriptionClassName,
  error,
  errorClassName,
}: FieldErrorAndDescriptionProps) =>
  error || description ? (
    <div>
      {description ? (
        <span className={`text-sm opacity-70 [&>a]:text-blue ${descriptionClassName || ''}`}>
          {description}
        </span>
      ) : null}
      {error ? (
        <p
          className={`text-sm text-danger [&>a]:text-blue ${errorClassName || ''}`}
          dangerouslySetInnerHTML={{ __html: error }}
        />
      ) : null}
    </div>
  ) : null
