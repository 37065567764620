import React from 'react'

const ChartsSkeleton = () => (
  <div
    className={
      'rounded-lg shadow-old-design flex gap-9 flex-col bg-white w-full md:w-[calc(50%-10px)] lg:w-full old-desktop:w-[calc(50%-10px)] p-[24px]'
    }
  >
    <div className={'flex justify-between animate-pulse'}>
      <div className={'flex flex-col gap-1'}>
        <div className={'rounded-md text-transparent h-[16px] w-[70px] bg-gray-600'}>.</div>
        <div className={' rounded-md text-transparent py-2 w-[120px] bg-gray-600'}>.</div>
      </div>
      <div className={`rounded-md px-11 bg-gray-600 text-transparent`}>.</div>
    </div>
    <div className={`py-[90px] rounded-md bg-gray-600 animate-pulse text-transparent`}>.</div>
  </div>
)

export default ChartsSkeleton
