import React from 'react'
import BaseButton from 'shared/components/button/components/base-button'
import { ButtonType } from 'shared/components/button/types/button-interface'

const SecondaryButton = (props: ButtonType) => (
  <BaseButton
    color={'text-darkblue'}
    hoverColor={'hover:text-white'}
    backgroundColor={'bg-transparent'}
    hoverBackgroundColor={'hover:bg-darkblue'}
    borderColor={'border-darkblue'}
    loaderType={'dark'}
    {...props}
  />
)
export default SecondaryButton
