import { ChartData, Chart as ChartJS, ScatterDataPoint } from 'chart.js'
import ChartBox from 'modules/main/components/chart-box-section/chart-box'
import ChartsSkeleton from 'modules/main/components/chart-box-section/charts-skeleton'
import { options } from 'modules/main/components/chart-box-section/constants'
import { ChartBoxSectionProps } from 'modules/main/components/chart-box-section/types'
import { useMain } from 'modules/main/hooks/use-main'
import { useRouter } from 'next/router'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import { Chart, getElementAtEvent } from 'react-chartjs-2'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { toShortDate } from 'shared/utils/date-time-format'

export interface LeadsChartBoxProps extends ChartBoxSectionProps {}

export const LeadsChartBox = ({ filter }: LeadsChartBoxProps) => {
  const chartRef = useRef<ChartJS<'line'>>(null)

  const { t } = useLocoTranslation()
  const { leads } = useMain(filter)
  const { user } = useUser()

  const router = useRouter()

  const [leadsData, setLeadsData] = useState<
    ChartData<'line', (number | ScatterDataPoint | null)[], string>
  >({ labels: [], datasets: [] })

  const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef
    if (!chart) {
      return
    }
    const element = getElementAtEvent(chart, event)
    if (!element.length) return
    const { index } = element[0]

    if (user) {
      router.push(
        `/contacts?limit=10&filter=%5B%7B"searchValue"%3A"${leads?.values[index].date}T00%3A00%3A00"%2C"compareOperation"%3A"EQUALS_DATE"%2C"searchField"%3A"created_at"%7D%5D`,
      )
    }
  }
  const onPointerMove = (e: MouseEvent) => {
    if (chartRef.current) {
      if (
        chartRef.current.getElementsAtEventForMode(
          e as unknown as Event,
          'nearest',
          {
            intersect: true,
          },
          true,
        ).length === 0
      ) {
        chartRef.current.canvas.style.cursor = 'default'
      } else {
        chartRef.current.canvas.style.cursor = 'pointer'
      }
    }
  }

  useEffect(() => {
    if (leads) {
      setLeadsData({
        labels: leads.values.map(value => toShortDate(value.date, user?.dashboardLocale)),
        datasets: [
          {
            data: leads.values.map(el => el.value),
            backgroundColor: '#F7FAFF',
            borderColor: 'rgba(180,214,255,0.5)',
            pointBackgroundColor: 'rgba(52,152,219,0.2)',
            pointBorderColor: 'rgba(52,152,219,0.1)',
            fill: true,
          },
        ],
      })
    }
  }, [leads, user])

  return leads ? (
    <ChartBox
      title={t('dashboard.main.new_leads').toUpperCase()}
      total={new Intl.NumberFormat(user?.dashboardLocale).format(leads.total)}
      diffPercents={leads.diffPercents}
      countDays={leads.countDays}
      dateSince={filter.dateSince}
      chart={
        <Chart
          ref={chartRef}
          type={'line'}
          onClick={onClick}
          onPointerMove={onPointerMove}
          height={200}
          data={leadsData}
          options={options}
          // plugins={[plugin]}
        />
      }
    />
  ) : (
    <ChartsSkeleton />
  )
}
