import React from 'react'

function PersonIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="#35E190"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.93709 10.61C10.8847 10.5968 11.8073 10.3037 12.5888 9.76761C13.3703 9.23151 13.9758 8.47632 14.3293 7.597C14.6828 6.71768 14.7684 5.75348 14.5754 4.82564C14.3823 3.8978 13.9193 3.04773 13.2445 2.38233C12.5697 1.71692 11.7132 1.26588 10.7827 1.08592C9.85225 0.90596 8.88935 1.00511 8.01508 1.3709C7.14082 1.7367 6.39421 2.35281 5.86915 3.14176C5.34408 3.93072 5.06399 4.8573 5.06409 5.80501C5.06841 6.44052 5.1979 7.06895 5.44515 7.6544C5.69241 8.23986 6.05259 8.77086 6.50512 9.21707C6.95765 9.66329 7.49366 10.016 8.08253 10.255C8.6714 10.494 9.30159 10.6146 9.93709 10.61ZM9.93709 13.01C6.68409 13.013 0.190094 14.622 0.190094 17.818V20.218H19.6831V17.818C19.6831 14.622 13.1901 13.013 9.93709 13.013V13.01Z" />
    </svg>
  )
}

export default PersonIcon
