import EventsSkeleton from 'modules/main/components/event-box-section/events-skeleton'
import { EventBoxSectionProps } from 'modules/main/components/event-box-section/types'
import RenderEvent from 'modules/main/components/render-event'
import { useMain } from 'modules/main/hooks/use-main'
import { MainEventType } from 'modules/main/types/main-interface'
import React, { useEffect, useState } from 'react'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'

export const EventBoxSection = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<EventBoxSectionProps>
>(function EventBoxSection({ filter }, ref) {
  const { t } = useLocoTranslation()
  const { events, revenue } = useMain(filter)
  const [eventsData, setEventsData] = useState<MainEventType[]>([])

  useEffect(() => {
    setEventsData(events?.items || [])
  }, [events])

  return (
    <div
      ref={ref}
      className={`rounded-lg overflow-hidden shadow-old-design bg-white flex-none lg:w-[326px] flex flex-col lg:h-[700px] relative`}
    >
      <span className={'text-darkblue font-inter text-[20px] font-bold px-[25px] py-[23px]'}>
        {t('dashboard.main.event.title')}
      </span>
      <hr className={'color-[#ebedf0] opacity-10'} />
      {events || eventsData.length !== 0 ? (
        eventsData.length !== 0 && (
          <>
            <div className={`px-[25px] pb-5 flex flex-col gap-3 overflow-hidden`}>
              <ul className={'flex flex-col'}>
                {eventsData.map((event, idx) => (
                  <li key={idx}>
                    <RenderEvent event={event} lastItem={idx === eventsData.length - 1} />
                  </li>
                ))}
              </ul>
            </div>
            <div
              className={
                'rounded-lg hidden lg:block absolute bottom-0 h-[50px] bg-gradient-to-b from-[hsla(0,0%,100%,0)] to-[#fff] w-[326px]'
              }
            />
          </>
        )
      ) : (
        <EventsSkeleton revenueLength={Object.keys(revenue || []).length} />
      )}
    </div>
  )
})
