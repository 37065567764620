import React from 'react'

function MoneyCancelIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.5"
      height="19.875"
      viewBox="0 0 28.5 19.875"
      {...props}
    >
      <g transform="translate(-1376.759 -16.413)">
        <g transform="translate(1377.009 16.663)">
          <path
            d="M1348.956,462h-7.45a6.115,6.115,0,0,0,7.45,7.092Z"
            transform="translate(-1321.396 -461.999)"
            fill="#b5c3d5"
          />
          <path
            d="M1341.3,461.749h7.907v7.538l-.191.046a6.363,6.363,0,0,1-7.754-7.382Zm7.407.5h-6.99a5.865,5.865,0,0,0,6.99,6.642Z"
            transform="translate(-1321.396 -461.999)"
            fill="#b5c3d5"
          />
          <path
            d="M894.4,687.154h7.45a6.115,6.115,0,0,0-7.45-7.092Z"
            transform="translate(-894.396 -669.194)"
            fill="#b5c3d5"
          />
          <path
            d="M902.053,687.4h-7.907v-7.538l.191-.046a6.365,6.365,0,0,1,7.755,7.382Zm-7.407-.5h6.99a5.864,5.864,0,0,0-6.99-6.642Z"
            transform="translate(-894.396 -669.194)"
            fill="#b5c3d5"
          />
          <circle
            cx="3.853"
            cy="3.853"
            r="3.853"
            transform="translate(9.946 5.127)"
            fill="#b5c3d5"
          />
          <path
            d="M3.853-.25a4.1,4.1,0,1,1-4.1,4.1A4.108,4.108,0,0,1,3.853-.25Zm0,7.707a3.6,3.6,0,1,0-3.6-3.6A3.607,3.607,0,0,0,3.853,7.457Z"
            transform="translate(9.946 5.127)"
            fill="#b5c3d5"
          />
          <path
            d="M912.079,478.29H895.955V463.668H920.4v7.567a6.306,6.306,0,0,1,1.559.707V462H894.4v17.96h17.935A7.464,7.464,0,0,1,912.079,478.29Z"
            transform="translate(-894.396 -461.999)"
            fill="#b5c3d5"
          />
          <path
            d="M912.657,480.209H894.146v-18.46h28.059v10.645l-.383-.241a6.063,6.063,0,0,0-1.5-.679l-.179-.053v-7.5H896.205V478.04H912.32l.009.241a7.229,7.229,0,0,0,.243,1.613Zm-18.011-.5h17.366a7.733,7.733,0,0,1-.169-1.169H895.705V463.418h24.941v7.633a6.538,6.538,0,0,1,1.059.456v-9.258H894.646Z"
            transform="translate(-894.396 -461.999)"
            fill="#b5c3d5"
          />
          <path
            d="M1324.349,672.157a4.53,4.53,0,1,0,4.53,4.53A4.529,4.529,0,0,0,1324.349,672.157Zm2.336,5.218h-4.671V676h4.671Z"
            transform="translate(-1300.878 -661.841)"
            fill="#b5c3d5"
          />
          <path
            d="M1324.349,671.907a4.78,4.78,0,1,1-4.779,4.78A4.785,4.785,0,0,1,1324.349,671.907Zm0,9.059a4.28,4.28,0,1,0-4.279-4.28A4.284,4.284,0,0,0,1324.349,680.966Zm-2.585-5.218h5.171v1.877h-5.171Zm4.671.5h-4.171v.877h4.171Z"
            transform="translate(-1300.878 -661.841)"
            fill="#b5c3d5"
          />
        </g>
      </g>
    </svg>
  )
}

export default MoneyCancelIcon
