import Router, { useRouter } from 'next/router'
import { ParsedUrlQueryInput } from 'querystring'
import { useEffect, useRef, useState } from 'react'
import { getFilterQuery } from 'shared/route-query/utils/get-filter-query'
import { setFilterQuery } from 'shared/route-query/utils/set-filter-query'

export const useFilter = <T>(defaultFilter: T) => {
  const { query, isReady } = useRouter()
  const queryRef = useRef(query)

  const [filter, setFilter] = useState<T>(defaultFilter)

  const filterKeys = useRef(Object.keys(filter) as Array<keyof T>)

  useEffect(() => {
    filterKeys.current = Object.keys(filter) as Array<keyof T>
  }, [filter])

  useEffect(() => {
    queryRef.current = query
  }, [query])

  useEffect(() => {
    if (!isReady) return

    setFilterQuery({ setFilter, queryRef, filterKeys })
  }, [isReady])

  useEffect(() => {
    if (!isReady) return
    const filterQuery = getFilterQuery({ filter, defaultFilter, filterKeys })

    const baseQueryKey = (Object.keys(queryRef.current) as Array<keyof T & string>).find(
      el => !filterKeys.current.includes(el),
    )
    const baseQuery = baseQueryKey ? { [baseQueryKey]: queryRef.current[baseQueryKey] } : {}

    const timeout = setTimeout(() => {
      Router.replace(
        {
          query: {
            ...baseQuery,
            ...(filterQuery as unknown as ParsedUrlQueryInput),
          },
        },
        undefined,
        { shallow: true },
      )
    })

    return function cleanUp() {
      clearTimeout(timeout)
    }
  }, [isReady, filter, defaultFilter])

  return { filter, setFilter }
}
