import React from 'react'
import BaseButton from 'shared/components/button/components/base-button'
import { ButtonType } from 'shared/components/button/types/button-interface'

const DangerButton = (props: ButtonType) => (
  <BaseButton
    color={'text-white'}
    hoverColor={'hover:text-white'}
    backgroundColor={'bg-danger'}
    hoverBackgroundColor={'hover:bg-danger'}
    {...props}
  />
)
export default DangerButton
