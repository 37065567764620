import React from 'react'

function ArrowDownSmoothIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width="17"
      height="10"
      viewBox="0 0 17 10"
      fill="#142C63"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7.89997 0.550032C7.99302 0.455547 8.10394 0.380513 8.22626 0.329296C8.34858 0.27808 8.47987 0.251704 8.61248 0.251704C8.74509 0.251704 8.87637 0.27808 8.99869 0.329296C9.12101 0.380513 9.23193 0.455547 9.32498 0.550032L16.456 7.79603C16.5938 7.9366 16.687 8.1147 16.724 8.30802C16.761 8.50134 16.7401 8.70129 16.664 8.8828C16.5879 9.06432 16.4599 9.21933 16.296 9.32841C16.1322 9.4375 15.9398 9.49581 15.743 9.49603H1.48098C1.28414 9.49581 1.09175 9.4375 0.927914 9.32841C0.764074 9.21933 0.636071 9.06432 0.559942 8.8828C0.483812 8.70129 0.462944 8.50134 0.49995 8.30802C0.536956 8.1147 0.630192 7.9366 0.767975 7.79603L7.89997 0.550032Z" />
    </svg>
  )
}

export default ArrowDownSmoothIcon
