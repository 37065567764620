import { LineProps } from 'modules/main/components/chart-box-section/types'

export const options: LineProps['options'] = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  layout: { padding: 5 },
  elements: {
    point: {
      radius: 3,
      hoverRadius: 4,
      hoverBorderColor: 'rgba(52,152,219,0.1)',
    },
    line: { cubicInterpolationMode: 'monotone', borderWidth: 4 },
  },
  plugins: {
    tooltip: { caretPadding: 5 },
    legend: { display: false },
  },
  scales: {
    x: {
      border: { display: false },
      grid: { display: false },
      ticks: {
        labelOffset: 10,
        color: '#8492a5',
        font: { size: 11, family: 'avertape' },
      },
    },
    y: {
      display: false,
    },
  },
}
