import React, { FC } from 'react'
import BaseButton from 'shared/components/button/components/base-button'
import { NeutralTypeEnum } from 'shared/components/button/components/neutral-button/enum/neutral-type-enum'
import {
  getHoverBorderColor,
  getHoverColor,
} from 'shared/components/button/components/neutral-button/utils/get-colors'
import { ButtonType } from 'shared/components/button/types/button-interface'

type NeutralButtonProps = {
  neutralType?: NeutralTypeEnum
} & ButtonType

const NeutralButton: FC<NeutralButtonProps> = ({ neutralType = NeutralTypeEnum.blue, ...rest }) => (
  <BaseButton
    color={'text-darkblue'}
    hoverColor={getHoverColor(neutralType)}
    backgroundColor={'bg-white'}
    hoverBackgroundColor={'hover:bg-white'}
    borderColor={'border-gray/30'}
    hoverBorderColor={getHoverBorderColor(neutralType)}
    {...rest}
  />
)
export default NeutralButton
