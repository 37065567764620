import {
  ContactFilterConditionEnum,
  ContactFilterContactStateEnum,
  ContactFilterDateConditionEnum,
  ContactFilterTagConditionEnum,
  ContactSearchFieldEnum,
  ContactSlugEnum,
} from 'modules/contacts/enums/contact-enum'

export const contactFilterTitles: Record<ContactSlugEnum, string> = {
  [ContactSlugEnum.email]: 'global.email',
  [ContactSlugEnum.first_name]: 'dashboard.user.first_name',
  [ContactSlugEnum.surname]: 'dashboard.user.last_name',
  [ContactSlugEnum.phone_number]: 'dashboard.user.phone_number',
  [ContactSlugEnum.country]: 'dashboard.user.country',
  [ContactSlugEnum.street_address]: 'dashboard.user.street_address',
  [ContactSlugEnum.city]: 'dashboard.user.city',
  [ContactSlugEnum.postcode]: 'dashboard.user.postal_code',
  [ContactSlugEnum.company_name]: 'dashboard.user.company_name',
  [ContactSlugEnum.tax_number]: 'dashboard.user.tax_number',
  [ContactSlugEnum.state]: 'dashboard.user.state_province',
}

export const contactFilterAdditionalTitles: Partial<Record<ContactSearchFieldEnum, string>> = {
  [ContactSearchFieldEnum.last_activity_date]: 'dashboard.contacts.filter.email_activity',
  [ContactSearchFieldEnum.created_at]: 'dashboard.contacts.filter.date_added',
  [ContactSearchFieldEnum.tag]: 'dashboard.contacts.filter.tag',
  [ContactSearchFieldEnum.state]: 'dashboard.contacts.filter.contact_state',
  [ContactSearchFieldEnum.affiliate]: 'dashboard.contacts.filter.referred_by_affiliate',
}

export const contactFilterConditionTitles: Record<ContactFilterConditionEnum, string> = {
  [ContactFilterConditionEnum.equals]: 'dashboard.contacts.filter.matches',
  [ContactFilterConditionEnum.not_equal]: 'dashboard.contacts.filter.not_matches',
  [ContactFilterConditionEnum.contains]: 'dashboard.contacts.filter.contains',
  [ContactFilterConditionEnum.does_not_contain]: 'dashboard.contacts.filter.not_contains',
  [ContactFilterConditionEnum.begins]: 'dashboard.contacts.filter.begins_with',
  [ContactFilterConditionEnum.ends]: 'dashboard.contacts.filter.ends_with',
  [ContactFilterConditionEnum.does_not_end]: 'dashboard.contacts.filter.not_ends_with',
  [ContactFilterConditionEnum.does_not_begin]: 'dashboard.contacts.filter.not_begins_with',
  [ContactFilterConditionEnum.not_set]: 'dashboard.contacts.filter.not_set',
}

export const contactFilterDateConditionTitles: Record<ContactFilterDateConditionEnum, string> = {
  [ContactFilterDateConditionEnum.greater_than]: 'dashboard.contacts.filter.greater_than',
  [ContactFilterDateConditionEnum.less_than]: 'dashboard.contacts.filter.less_than',
  [ContactFilterDateConditionEnum.equals_date]: 'dashboard.contacts.filter.equals_date',
}

export const contactFilterTagConditionTitle: Record<ContactFilterTagConditionEnum, string> = {
  [ContactFilterTagConditionEnum.in_list]: 'dashboard.contacts.filter.in',
  [ContactFilterTagConditionEnum.not_in_list]: 'dashboard.contacts.filter.not_in',
  [ContactFilterTagConditionEnum.without]: 'dashboard.contacts.filter.without',
}

export const contactFilterContactStateTitles: Record<ContactFilterContactStateEnum, string> = {
  [ContactFilterContactStateEnum.state_active]: 'dashboard.contacts.filter.state_active',
  [ContactFilterContactStateEnum.state_unsubscribed]:
    'dashboard.contacts.filter.state_unsubscribed',
  [ContactFilterContactStateEnum.state_bounced]: 'dashboard.contacts.filter.state_bounced',
  [ContactFilterContactStateEnum.state_made_payments]:
    'dashboard.contacts.filter.state_made_payments',
  [ContactFilterContactStateEnum.state_not_made_payments]:
    'dashboard.contacts.filter.state_not_made_payments',
}

export const contactAdditionalFilters: Record<
  keyof typeof ContactSearchFieldEnum,
  keyof typeof ContactSearchFieldEnum
> = {
  tag: 'tag',
  last_activity_date: 'last_activity_date',
  created_at: 'created_at',
  state: 'state',
  affiliate: 'affiliate',
  removed_at: 'removed_at',
  removed_reason_type: 'removed_reason_type',
}
