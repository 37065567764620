import { CountryEnum } from 'shared/enums/country-enum'
import { LocaleEnum } from 'shared/enums/locale-enum'
import { getLanguageCodeByLocale } from '../enums/language-code-enum'

const countries = require('i18n-iso-countries')
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

export interface CountryDataInterface {
  id: CountryEnum
  caption: string
}

export const getCountryData = (locale?: LocaleEnum): CountryDataInterface[] => {
  if (locale) {
    countries.registerLocale(
      require(`i18n-iso-countries/langs/${getLanguageCodeByLocale(locale)}.json`),
    )
    return Object.values(CountryEnum).map(el => ({
      id: el,
      caption: countries.getName(el, getLanguageCodeByLocale(locale), { select: 'alias' }),
    }))
  } else {
    return Object.values(CountryEnum).map(el => ({
      id: el,
      caption: countries.getName(el, 'en', { select: 'alias' }),
    }))
  }
}

export const getSingleCountry = (key: CountryEnum, locale?: LocaleEnum): string => {
  if (locale) {
    countries.registerLocale(
      require(`i18n-iso-countries/langs/${getLanguageCodeByLocale(locale)}.json`),
    )
    return countries.getName(key, getLanguageCodeByLocale(locale), { select: 'alias' })
  } else {
    return countries.getName(key, 'en', { select: 'alias' })
  }
}
