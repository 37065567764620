import { NeutralTypeEnum } from 'shared/components/button/components/neutral-button/enum/neutral-type-enum'

export const getHoverColor = (type: NeutralTypeEnum) => {
  switch (type) {
    case NeutralTypeEnum.danger:
      return 'hover:text-danger'
    case NeutralTypeEnum.blue:
      return 'hover:text-blue'
    default:
      return 'text-blue'
  }
}

export const getHoverBorderColor = (type: NeutralTypeEnum) => {
  switch (type) {
    case NeutralTypeEnum.danger:
      return 'hover:border-danger'
    case NeutralTypeEnum.blue:
      return 'hover:border-blue'
    default:
      return 'border-blue'
  }
}
