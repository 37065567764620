import { ChartData, ScatterDataPoint } from 'chart.js'
import ChartBox from 'modules/main/components/chart-box-section/chart-box'
import ChartsSkeleton from 'modules/main/components/chart-box-section/charts-skeleton'
import { options } from 'modules/main/components/chart-box-section/constants'
import { ChartBoxSectionProps } from 'modules/main/components/chart-box-section/types'
import { useMain } from 'modules/main/hooks/use-main'
import {
  MainRevenueInterface,
  MainRevenueItemInterface,
} from 'modules/main/types/main-interface'
import { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { CurrencyEnum } from 'shared/enums/currency-enum'
import { useLocoTranslation } from 'shared/hooks/use-loco-translation'
import useUser from 'shared/hooks/use-user'
import { currencyFormat } from 'shared/utils/currency-format'
import { toShortDate } from 'shared/utils/date-time-format'

export interface RevenueChartBoxProps extends ChartBoxSectionProps {}

export const RevenueChartBox = ({ filter }: RevenueChartBoxProps) => {
  const { t } = useLocoTranslation()

  const { revenue } = useMain(filter)
  const { user } = useUser()

  const [revenueData, setRevenueData] = useState<
    {
      currency: CurrencyEnum
      data: MainRevenueItemInterface
      chartData: ChartData<'line', (number | ScatterDataPoint | null)[], string>
    }[]
  >([])

  useEffect(() => {
    if (revenue) {
      setRevenueData(
        (Object.keys(revenue) as Array<keyof MainRevenueInterface>)
          .sort((a, b) => revenue[b].total - revenue[a].total)
          .map(key => ({
            currency: key,
            data: revenue[key],
            chartData: {
              labels: revenue[key].values.map(value =>
                toShortDate(value.date, user?.dashboardLocale),
              ),
              datasets: [
                {
                  type: 'line',
                  data: revenue[key].values.map(el => Math.round(el.value / 100)),
                  backgroundColor: '#F7FAFF',
                  borderColor: 'rgba(180,214,255,0.5)',
                  pointBackgroundColor: 'rgba(52,152,219,0.2)',
                  pointBorderColor: 'rgba(52,152,219,0.1)',
                  fill: true,
                },
              ],
            },
          })),
      )
    }
  }, [revenue, user?.dashboardLocale])

  return (
    <>
      {revenue ? (
        revenueData.map((rev, idx) => (
          <ChartBox
            key={idx}
            title={t('dashboard.main.sales').toUpperCase()}
            total={currencyFormat(
              Math.round(rev.data.total / 100),
              rev.currency,
              user?.dashboardLocale,
              true,
            )}
            diffPercents={rev.data.diffPercents}
            countDays={rev.data.countDays}
            dateSince={filter.dateSince}
            chart={<Line height={200} data={rev.chartData} options={options} />}
          />
        ))
      ) : (
        <ChartsSkeleton />
      )}
    </>
  )
}
