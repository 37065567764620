import {
  CategoryScale,
  Chart as ChartJS,
  Tooltip as ChartTooltip,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
} from 'chart.js'
import React from 'react'
import { LeadsChartBox } from 'modules/main/components/chart-box-section/leads-chart-box'
import { RevenueChartBox } from 'modules/main/components/chart-box-section/revenue-chart-box'
import { ChartBoxSectionProps } from 'modules/main/components/chart-box-section/types'

ChartJS.register(
  CategoryScale,
  LinearScale,
  Filler,
  PointElement,
  LineElement,
  Title,
  Legend,
  ChartTooltip,
)
export const ChartBoxSection = React.forwardRef<
  HTMLDivElement,
  React.PropsWithRef<ChartBoxSectionProps>
>(function ChartBoxSection({ className, ...props }, ref) {
  return (
    <div
      ref={ref}
      className={`flex flex-wrap gap-5 old-desktop:gap-5 justify-between flex-col md:flex-row lg:flex-col ${className} h-fit old-desktop:flex-row`}
    >
      <LeadsChartBox {...props} />
      <RevenueChartBox {...props} />
    </div>
  )
})
