import React from 'react'

function MoneyReturnIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.501"
      height="19.993"
      viewBox="0 0 28.501 19.993"
      {...props}
    >
      <g transform="translate(-1365.793 -6.208)">
        <g transform="translate(1366.044 6.458)">
          <g transform="translate(18.113 10.469)">
            <g transform="translate(0)">
              <path
                d="M1294.017,246.759c-.564-3.762-5.946-4.28-8.082-1.652-.107-.224-.213-.449-.315-.675a.607.607,0,0,0-.829-.217.62.62,0,0,0-.217.829c.248.553.517,1.1.788,1.637a.535.535,0,0,0,.274.268.7.7,0,0,0,.562.132,14.655,14.655,0,0,1,1.475-.16c.777-.046.781-1.259,0-1.212-.223.013-.446.033-.669.055,1.678-1.768,5.429-1.444,5.843,1.318C1292.963,247.853,1294.131,247.524,1294.017,246.759Z"
                transform="translate(-1284.501 -243.468)"
                fill="#00a0ff"
              />
              <path
                d="M1293.339,247.771h0a.725.725,0,0,1-.739-.653,2.694,2.694,0,0,0-2.869-2.2,3.741,3.741,0,0,0-1.955.541.772.772,0,0,1,.731.791.872.872,0,0,1-.819.921,14.307,14.307,0,0,0-1.45.157,1.1,1.1,0,0,1-.177.014.92.92,0,0,1-.556-.178.794.794,0,0,1-.367-.372c-.277-.553-.545-1.095-.792-1.646a.849.849,0,0,1,.755-1.261.793.793,0,0,1,.748.444c.047.1.1.212.152.331a5.2,5.2,0,0,1,3.695-1.443,4.326,4.326,0,0,1,4.569,3.5.879.879,0,0,1-.2.715A.966.966,0,0,1,1293.339,247.771Zm-3.608-3.354a3.764,3.764,0,0,1,2.158.64,2.893,2.893,0,0,1,1.205,1.986.229.229,0,0,0,.245.227.473.473,0,0,0,.343-.161.377.377,0,0,0,.087-.315,3.843,3.843,0,0,0-4.075-3.078,4.585,4.585,0,0,0-3.566,1.547l-.249.306-.171-.356c-.128-.266-.229-.482-.318-.681a.314.314,0,0,0-.292-.149.369.369,0,0,0-.183.045.389.389,0,0,0-.116.511c.243.542.509,1.079.784,1.627a.29.29,0,0,0,.147.149l.035.015.029.024a.456.456,0,0,0,.361.077,14.8,14.8,0,0,1,1.5-.163.37.37,0,0,0,.349-.406.287.287,0,0,0-.291-.308h-.029c-.2.012-.412.03-.658.055l-.671.068.464-.489A4.053,4.053,0,0,1,1289.731,244.418Z"
                transform="translate(-1284.501 -243.468)"
                fill="#00a0ff"
              />
              <path
                d="M1300.009,347.246c.108.224.213.449.315.675a.608.608,0,0,0,.829.217.62.62,0,0,0,.218-.829c-.248-.553-.517-1.095-.788-1.637a.538.538,0,0,0-.275-.268.7.7,0,0,0-.562-.132,14.646,14.646,0,0,1-1.476.16c-.777.047-.781,1.259,0,1.212.224-.013.446-.033.669-.055-1.678,1.768-5.43,1.443-5.844-1.318-.116-.771-1.284-.442-1.169.322C1292.492,349.356,1297.873,349.874,1300.009,347.246Z"
                transform="translate(-1291.556 -339.861)"
                fill="#00a0ff"
              />
              <path
                d="M1296.25,349.136h0a4.326,4.326,0,0,1-4.569-3.5.879.879,0,0,1,.2-.715.966.966,0,0,1,.722-.334.725.725,0,0,1,.739.653,2.694,2.694,0,0,0,2.869,2.2,3.742,3.742,0,0,0,1.955-.541.772.772,0,0,1-.731-.791.872.872,0,0,1,.819-.921,14.3,14.3,0,0,0,1.451-.157.965.965,0,0,1,.733.163.8.8,0,0,1,.367.371c.289.578.548,1.1.793,1.646a.849.849,0,0,1-.755,1.261.793.793,0,0,1-.748-.444c-.048-.106-.1-.215-.152-.331A5.2,5.2,0,0,1,1296.25,349.136Zm-3.645-4.053a.473.473,0,0,0-.343.161.376.376,0,0,0-.087.314,3.843,3.843,0,0,0,4.074,3.078h0a4.585,4.585,0,0,0,3.565-1.547l.249-.306.171.355c.124.258.225.474.318.681a.314.314,0,0,0,.292.149.369.369,0,0,0,.183-.045.39.39,0,0,0,.116-.512c-.241-.537-.5-1.055-.783-1.627a.292.292,0,0,0-.147-.149l-.035-.015-.029-.024a.457.457,0,0,0-.361-.077,14.791,14.791,0,0,1-1.5.163.371.371,0,0,0-.349.407.287.287,0,0,0,.291.308h.029c.2-.012.408-.029.659-.055l.67-.068-.464.488a4.053,4.053,0,0,1-2.908,1.173,3.764,3.764,0,0,1-2.158-.64,2.893,2.893,0,0,1-1.206-1.986A.229.229,0,0,0,1292.6,345.082Z"
                transform="translate(-1291.556 -339.861)"
                fill="#00a0ff"
              />
            </g>
          </g>
          <path
            d="M1331.552,30h-7.443a6.109,6.109,0,0,0,7.443,7.085Z"
            transform="translate(-1303.95 -30)"
            fill="#00a0ff"
          />
          <path
            d="M1323.9,29.75h7.9v7.532l-.191.046a6.359,6.359,0,0,1-7.748-7.375Zm7.4.5h-6.983a5.859,5.859,0,0,0,6.983,6.635Z"
            transform="translate(-1303.95 -30)"
            fill="#00a0ff"
          />
          <path
            d="M877,255.148h7.443A6.109,6.109,0,0,0,877,248.063Z"
            transform="translate(-877 -237.205)"
            fill="#00a0ff"
          />
          <path
            d="M884.65,255.4h-7.9v-7.532l.191-.046a6.359,6.359,0,0,1,7.748,7.375Zm-7.4-.5h6.983a5.859,5.859,0,0,0-6.983-6.635Z"
            transform="translate(-877 -237.205)"
            fill="#00a0ff"
          />
          <circle cx="3.85" cy="3.85" r="3.85" transform="translate(9.968 5.122)" fill="#00a0ff" />
          <path
            d="M3.85-.25a4.1,4.1,0,1,1-4.1,4.1A4.1,4.1,0,0,1,3.85-.25Zm0,7.7a3.6,3.6,0,1,0-3.6-3.6A3.6,3.6,0,0,0,3.85,7.449Z"
            transform="translate(9.968 5.122)"
            fill="#00a0ff"
          />
          <path
            d="M894.711,46.276H878.562V31.667h24.479v7.56a6.318,6.318,0,0,1,1.562.707V30H877V47.943h17.963A7.44,7.44,0,0,1,894.711,46.276Z"
            transform="translate(-877 -30)"
            fill="#00a0ff"
          />
          <path
            d="M895.289,48.193H876.75V29.75h28.1V40.386l-.383-.24a6.071,6.071,0,0,0-1.5-.679l-.179-.053v-7.5H878.812V46.026h16.14l.009.241a7.2,7.2,0,0,0,.244,1.611Zm-18.039-.5h17.393a7.718,7.718,0,0,1-.17-1.167H878.312V31.417h24.979v7.626a6.551,6.551,0,0,1,1.062.456V30.25h-27.1Z"
            transform="translate(-877 -30)"
            fill="#00a0ff"
          />
        </g>
      </g>
    </svg>
  )
}

export default MoneyReturnIcon
