export enum MainEventsEnum {
  contact_created = 'contact_created',
  payment_subscription_created = 'payment_subscription_created',
  payment_subscription_stopped = 'payment_subscription_stopped',
  payment_subscription_completed = 'payment_subscription_completed',
  recurring_charge_failed = 'recurring_charge_failed',
  recurring_charge_succeed = 'recurring_charge_succeed',
  one_shot_charge_succeed = 'one_shot_charge_succeed',
}

export const mainEventsType = {
  [MainEventsEnum.contact_created]: 'dashboard.main.event.type.contact_created',
  [MainEventsEnum.payment_subscription_created]:
    'dashboard.main.event.type.payment_subscription_created',
  [MainEventsEnum.payment_subscription_stopped]:
    'dashboard.main.event.type.payment_subscription_stopped',
  [MainEventsEnum.payment_subscription_completed]:
    'dashboard.main.event.type.payment_subscription_completed',
  [MainEventsEnum.recurring_charge_failed]: 'dashboard.main.event.type.recurring_charge_failed',
  [MainEventsEnum.recurring_charge_succeed]: 'dashboard.main.event.type.recurring_charge_succeed',
  [MainEventsEnum.one_shot_charge_succeed]: 'dashboard.main.event.type.one_shot_charge_succeed',
}

export const mainEventsDescription = {
  [MainEventsEnum.contact_created]: 'dashboard.main.event.message.contact_created',
  [MainEventsEnum.payment_subscription_created]:
    'dashboard.main.event.message.payment_subscription_created',
  [MainEventsEnum.payment_subscription_stopped]:
    'dashboard.main.event.message.payment_subscription_stopped',
  [MainEventsEnum.payment_subscription_completed]:
    'dashboard.main.event.message.payment_subscription_completed',
  [MainEventsEnum.recurring_charge_failed]: 'dashboard.main.event.message.recurring_charge_failed',
  [MainEventsEnum.recurring_charge_succeed]:
    'dashboard.main.event.message.recurring_charge_succeed',
  [MainEventsEnum.one_shot_charge_succeed]: 'dashboard.main.event.message.one_shot_charge_succeed',
}
