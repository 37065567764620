import { useEffect, useState } from 'react'
import useUser from 'shared/hooks/use-user'
import { useFilter } from 'shared/route-query/hooks/use-filter'

type FilterWithDateType = { dateSince: string | null; dateTill: string | null }

export const useDateFilter = <T extends FilterWithDateType>(
  defaultStaticFilter: T,
  getDefaultDateRange?: (timeZone?: string) => [string, string],
) => {
  const { user } = useUser()
  const [defaultFilter, setDefaultFilter] = useState<T>(defaultStaticFilter)

  const { filter, setFilter } = useFilter(defaultFilter)

  useEffect(() => {
    if (!user || !getDefaultDateRange) return

    const [defaultDateSince, defaultDateTill] = getDefaultDateRange(user?.timezone)

    setDefaultFilter(prev => ({
      ...prev,
      dateSince: defaultDateSince,
      dateTill: defaultDateTill,
    }))
  }, [getDefaultDateRange, user])

  useEffect(() => {
    if (filter.dateTill || filter.dateSince || !defaultFilter.dateSince || !defaultFilter.dateTill)
      return
    if (user) {
      setFilter(prev => ({
        ...prev,
        dateSince: defaultFilter.dateSince,
        dateTill: defaultFilter.dateTill,
      }))
    }
  }, [
    user,
    defaultFilter.dateTill,
    filter.dateSince,
    filter.dateTill,
    setFilter,
    defaultFilter.dateSince,
  ])

  return { filter, setFilter }
}
